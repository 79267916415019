@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    .app-container {
        @apply container mx-auto px-2 md:px-4;
    }

    .filter-btn {
        @apply min-w-20 bg-transparent border focus:outline-none py-1 font-medium rounded-md text-sm px-2 mb-2
    hover:bg-[#9f844d44] hover:text-slate-300;
        &:not(:last-child) {
            @apply me-2;
        }
    }

    #menu-btn {
        @apply lg:hidden flex flex-col items-center justify-center;
    }
}

:root {
    --primary-color: #9f844d; //#facd82 #9f844d
    --bg-color: #010101;
}

::view-transition {
    position: fixed;
    inset: 0;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
}

@keyframes fade-out {
    to {
        opacity: 0;
    }
}

@keyframes slide-from-right {
    from {
        transform: translateX(30px);
    }
}

@keyframes slide-to-left {
    to {
        transform: translateX(-30px);
    }
}

::view-transition-old(*),
::view-transition-new(*) {
    animation: none; /* Prevent the default animation, so both views remain opacity:1 throughout the transition */
    mix-blend-mode: normal; /* Use normal blending, so the new view sits on top and obscures the old view */
    height: 100%; /* Make the height the same as the group, meaning the view size might not match its aspect-ratio. */
    overflow: clip; /* Clip any overflow of the view */
}

/* The old view is the thumbnail */
::view-transition-old(*) {
    object-fit: contain; /* Maintain the aspect ratio of the view, by shrinking it to fit within the bounds of the element */
}

/* The new view is the heading image */
::view-transition-new(*) {
    object-fit: cover; /* Maintain the aspect ratio of the view, by growing it to cover the bounds of the element */
}

::view-transition-old(root) {
    animation:
        90ms cubic-bezier(0.4, 0, 1, 1) both fade-out,
        300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-left;
}

::view-transition-new(root) {
    animation:
        210ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in,
        300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-right;
}

html,
body {
    height: 100%;
    width: 100%;
    font-family: 'Exo', sans-serif !important;
    color: var(--primary-color);
    background-color: var(--bg-color);
    margin: 0;
    padding: 0;
    border: 0;
    // overflow: hidden;
    overflow-x: hidden;
    font-size: clamp(1rem, 2vw, 1.125rem);
    box-sizing: border-box;

    font-weight: 400 !important;
    line-height: 1.6em;
    letter-spacing: 2px;
    -webkit-text-size-adjust: 100%;
    // line-height: 1;
    -webkit-font-smoothing: antialiased; /* For WebKit browsers */
    -moz-osx-font-smoothing: grayscale; /* For macOS */
    text-rendering: geometricPrecision;
}
// @media (min-width: 992px) {
//     body {
//         font-size: clamp(1rem, 2vw, 1.5rem);
//     }
// }

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    user-select: none !important;
    outline: none;
    outline-offset: 0.05em;
}

h1,
h2,
h3,
h4,
h5,
h5,
.title {
    font-weight: 500 !important;
    color: #fff;
}

.mie-1 {
    &:not(:last-child) {
        margin-inline-end: 1em;
    }
}

a:focus,
button:focus,
input:focus,
select:focus,
textarea:focus {
    box-shadow: none;
    outline: none;
    outline-offset: 0.05em;
}

.app-bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-color: transparent;
}

.button-primary {
    border: 1px solid #654b22;
    color: #ffffff !important;
    padding: 0.5rem 0.75rem;
    margin-top: 0.5em;
    text-decoration: none;
    font-size: 0.55em;
    outline: none;
    display: inline-block;
    text-align: center;
    background-color: transparent;
    transition: all 200ms ease-in-out;
    &:hover {
        background-color: #d4aa6557;
        color: #fff !important;
    }
}

.container {
    display: block;
    margin: 0 auto;
    max-width: 1180px;
    width: 100%;
}

*::selection {
    background-color: var(--primary-color);
    color: var(#fff);
}

::selection {
    color: #fff;
}

.spacer {
    flex: 1 1 auto;
}

input {
    background-color: #424242;
    color: var(--primary-color);
}

a {
    text-decoration: none;
    color: var(--primary-color);
}

.white-black {
    transition:
        transform 175ms ease-in,
        filter 250ms ease-in;
    filter: grayscale(1);
}

img.colored-on-hover:hover,
.active img.colored-on-hover {
    transform: scale(1.1);
    filter: grayscale(0);
    opacity: 1;
}

::-webkit-scrollbar {
    width: 5px;
}

/* Track */

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(59, 59, 59);
    border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
    background: rgb(59, 59, 59);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--primary-color);
}

.content > div > p {
    font-size: 0.85em;
    margin-bottom: 1.5em !important;
}

.title {
    font-size: 1.6em;
    margin-bottom: 0.5em;
    // text-transform: uppercase;
}

/*NEW STYLES*/
.fixed-footer {
    iea-app-footer {
        position: fixed;
        bottom: 0;
        width: 100%;
    }
}

.swiper-button-prev,
.swiper-button-next {
    color: var(--primary-color) !important;
}

@media only screen and (max-width: 600px) {
    .swiper-pagination-bullet {
        width: 90px;
        height: 60px;
        min-width: 90px;
    }
}

.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    height: 85px;
    width: 100%;
    padding: 0;
    flex: 1;
    margin-bottom: 0;
    display: flex;
    flex-flow: row nowrap;
}
.swiper-pagination-bullet {
    opacity: 1;
    background: rgba(0, 0, 0, 0);
    cursor: pointer;
    margin: 0rem;
    width: 100px;
    height: 85px;
    min-width: 100px;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
}

.row.card {
    display: flex;
    flex-direction: column;
    margin-block: 4rem;
    gap: 2rem;
    line-height: 1.5;
    .image-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            aspect-ratio: 1 / 1;
            min-width: 320px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    .button {
        min-width: 180px;
        width: fit-content;
        font-size: medium;
        margin-block: 1rem;
    }
}

@media only screen and (min-width: 840px) {
    .row.card {
        flex-direction: row;
        gap: 4rem;
        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .image-wrapper {
            flex: 0 0 33.33%;
        }
    }

    .row:nth-child(even) {
        flex-direction: row-reverse;
    }
}

app-blog-detail {
    .wrapper {
        p {
            margin-block-end: 1em !important;
        }
    }
}
